import React, {useState} from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from 'axios';

// import {gql, useQuery} from "@apollo/client";
import {Link} from "gatsby";
import Avatar from "../images/avatar-placeholder.png";
import queryString from "query-string";

const config = require('../config/default');

function SearchPeoplePage() {
    const search = typeof window !== `undefined` ? location.search : {q: '/none/'}
    const urlState = queryString.parse(search)

    const [state, setState] = useState({
        data: {
            allPeople: {
                edges: []
            }
        },
        loading: false,
        is_search: true
    });


    let SEARCH_NAMES = function (search) {
        if (search === null) return []

        let query = `
            query($search: String) {
              allPeople(search: $search, first: 10) {
                edges {
                  node {
                    id
                    name
                    sortname
                    image
                    partytype
                  }
                }
              }

            }
          `.replace(/\n/g, ' ');

        axios.post(config.GRAPHQL_API_URL, {
            query: query,
            variables: {
                "search": search
            }
        })
            .then(function (response) {
                // console.log(response.data.data);
                setState({
                    "is_search": false,
                    "loading": false,
                    "data": response.data.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        setState({
            "is_search": false,
            "loading": true,
            "data": {
                "allPeople": {
                    "edges": []
                }
            }
        });


    }

    let query = urlState.q ? `${urlState.q}` : '';
    if (state.is_search) SEARCH_NAMES(query);


    let loading = state.loading;
    let data = state.data;

    const getPartyName = (party) => {
        if (party === 'Parliament') {
            return "วุฒิสภา"
        }
        return party;
    }

    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title="Search-People"
            />

            <section className="flex flex-col items-center md:flex-row m-10">
                <div className="md:w-2/3 md:mr-8">
                    <h1 className="font-bold text-2xl">รายชื่อที่เกี่ยวข้อง</h1><br/>
                    <div className="">
                        <ul className="list-none ">
                            {data && data.allPeople.edges.length > 0 ?
                                data.allPeople.edges.map(item => (
                                    <li key={item.node.id}>
                                        <Link className="block hover:bg-yellow-400 rounded p-3 cursor-pointer"
                                              to={item.node.partytype ==="PERSON"? `/person?id=${item.node.id}`: item.node.partytype ==="COMPANY"? `/company?id=${item.node.id}`: `/political-party?id=${item.node.id}`}>
                                            <div className="flex">
                                                {item.node.image? <img src={item.node.image} alt="avatar image" className="rounded-full w-8 h-8 object-cover"/>:
                                                    <img src={Avatar} alt="avatar image" className="rounded-full w-8 h-8"/>
                                                }
                                                <div className="ml-4">{getPartyName(item.node.name)}</div>
                                            </div>
                                        </Link>
                                    </li>
                                )) :
                                <li className="p-3 rounded bg-gray-100 text-gray-600 text-center italic">
                                    {loading ?
                                        <span>Loading &hellip;</span> :
                                        <span>No person matched query</span>
                                    }
                                </li>}
                        </ul>
                    </div>
                </div>
            </section>
        </Layout>
    );
}


export default SearchPeoplePage;
